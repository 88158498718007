window.$ = window.jQuery = require('jquery');
window.Cookies = require('js-cookie');

require('./vendor/jqueryUI');

require('./vendor/doubleTapToGo');
require('./vendor/fancybox');
require('slick-carousel');
require('./vendor/match-height');

require('./scripts/cookie');
require('./scripts/event');

import header from './scripts/header';
import merge from './scripts/merge';
import navigation from './scripts/navigation';
import setBackgroundImage from './scripts/setBackgroundImage';
import toggle from './scripts/toggle';
import googleReCaptcha from './scripts/googleRecaptcha';
import 'simplebar';

// Webshop includes
import checkout from './scripts/checkout';
import basket from './scripts/basket';
import articles from './scripts/articles';
import filters from './scripts/filters';

/**
 * Main Scripts
 *
 * This main scripts file is where you'll write most
 * of your (jQuery) scripts.
 */

(function ($) {

    /**
     * As soon as this file is loaded, jQuery will remove the `no-jquery`
     * class from the `body`. This indicates that jQuery has been loaded.
     */
    $('body').removeClass('no-jquery');

    /**
     * As soon as this file is loaded, jQuery will remove the `u-jquery`
     * class from the hidden `divs`. This indicates that jQuery has been loaded.
     */
    $('.u-jquery').removeClass('u-jquery');


    /**
     * Initialize Google reCaptcha
     */
    googleReCaptcha();

    /**
     * Object with breakpoint sizes defined. These are
     * based on Bootstrap's Grid.
     *
     * @type Object
     */
    var breakpoints = {
        "xs": 480,
        "sm": 768,
        "md": 992,
        "lg": 1200
    };

    // Header
    header(breakpoints);

    $(window).resize(function () {
        header(breakpoints);
    });

    // Navigation
    navigation(breakpoints);

    /**
     * Make sure touch-enabled devices can view the drop-down menus
     * starting from a landscape mode (> breakpoints.sm)
     */
    $(window).resize(function () {
        if ($(window).outerWidth() > breakpoints.sm) {
            $('.nav .nav__item:has(ul)').doubleTapToGo();
        }
    });

    if ($(window).width() > 991) {
        if($('#brasserie-navigation').length){
            var distance = $('#brasserie-navigation').offset().top - 200,
                $window = $(window),
                footerDistance = $('footer').offset().top - 550;



            if ( $window.scrollTop() >= distance && $window.scrollTop() < footerDistance) {
                // Your div has reached the top
                $('#brasserie-navigation').css({ 'position': 'fixed', 'top': '200px' });
            } else if ( $window.scrollTop() >= footerDistance ){
                $('#brasserie-navigation').css({ 'position': 'relative', 'top': footerDistance - distance });
            } else {
                $('#brasserie-navigation').css({ 'position': 'relative', 'top': '0' });
            }

            $window.scroll(function() {

                if ( $window.scrollTop() >= distance && $window.scrollTop() < footerDistance) {
                    // Your div has reached the top
                    $('#brasserie-navigation').css({ 'position': 'fixed', 'top': '200px' });
                } else if ( $window.scrollTop() >= footerDistance ){
                    $('#brasserie-navigation').css({ 'position': 'relative', 'top': footerDistance - distance });
                } else {
                    $('#brasserie-navigation').css({ 'position': 'relative', 'top': '0' });
                }
            });
        }
    }

    $(document).ready( function() {
        var now = new Date();
        var month = (now.getMonth() + 1);
        var day = now.getDate();
        var dayTomorrow = (now.getDate() + 1);
        if (month < 10)
            month = "0" + month;
        if (day < 10)
            day = "0" + day;
        if (dayTomorrow < 10)
            dayTomorrow = "0" + dayTomorrow;
        var today = now.getFullYear() + '-' + month + '-' + day;
        var tomorrow = now.getFullYear() + '-' + month + '-' + dayTomorrow;
        $('#Arrival').val(today);
        $('#Departure').val(tomorrow);
    });

    $('#Arrival').blur(function(){
        let tt = document.getElementById('Arrival').value;
        let givenDate = new Date(tt);
        let newDate = new Date(givenDate);
        newDate.setDate(newDate.getDate() + 1);
        console.log(givenDate);
        var dd = newDate.getDate();
        var mm = newDate.getMonth() + 1;
        var y = newDate.getFullYear();
        console.log(newDate);
        if (mm < 10)
            mm = "0" + mm;
        if (dd < 10)
            dd = "0" + dd;
        var nextDay = y + '-' + mm + '-' + dd;
        $('#Departure').val(nextDay);
    });

    // Merge
    merge(breakpoints);

    $(window).resize(function () {
        merge(breakpoints);
    });

    // Toggle
    toggle(breakpoints);

    $(window).resize(function () {
        toggle(breakpoints);
    });

    // Replace `img` with `background-image` in parent `div`
    setBackgroundImage();

    // Webshop
    basket();
    checkout();
    articles({
        timeoutInfoMessages: 4000
    });
    filters({
        afterLoad: function () {
            matchHeightArticles();
        },
        afterNavigation: function () {
            $('html,body').animate({
                scrollTop: $(".cms-shop-articles").offset().top - 100
            }, 500);
        }
    });

    $('.tab').on('mouseenter', function(){
        $(this).animate({
            'right': '0'
        }, 'slow');
    });

    $('.tab').on('mouseleave', function(){
        $(this).stop(true).animate({
            'right': '-175px'
        }, 'slow');
    });

    // MatchHeight articles e.d.
    function matchHeightArticles() {
        $('.product__item .info').matchHeight({
            byRow: true
        });
        $('.content__menu .inner .info h2').matchHeight({
            byRow: true
        });
        $('.news .item h3').matchHeight({
            byRow: true
        });
        $('.product .matchingcolumns').matchHeight({
            byRow: true
        });
        $('.search-block').matchHeight({
            byRow: true
        });
        $('.item .image__col').matchHeight({
            byRow: true
        });
        $('.item .info__col').matchHeight({
            byRow: true
        });
    }

    matchHeightArticles();

    // Fancybox
    $(".fancybox, .lightbox").fancybox({
        // Options will go here
    });

    // Slider Hero
    $('.js-hero-slider').slick({
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
        rows: 0,
        dots: true,
        autoplaySpeed: 4000
    });

    // Categories slider
    $('.js-brandslider section').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        pauseOnHover: false,
        swipeToSlide: true,
        focusOnSelect: true,
        arrows: false,
        rows: 0,
        dots: false,
        autoplay: true,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }, {
            breakpoint: 991,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
            }
        },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    });

    $('.room-slider .inner').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        dots: false,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000
    });


    // Search toggle
    $(".header .search button").click(function (e) {
        if ($(window).outerWidth() > breakpoints.md) {
            e.preventDefault();
            $('.header .search input[type=search]').focus();
        }

        var form = $(this).parent('form');
        var status = $(this).attr('data-active');
        var textHasBeenInserted = $(this).siblings('.box').find('.keywords').val().length;

        if (status === 'false') {
            $('.header .search').toggleClass("active");
            $('.header .searchbar').toggleClass("active");

            $(this).attr('data-active', 'true');
        } else if (textHasBeenInserted === 0) {
            $('.header .search').toggleClass("active");
            $('.header .searchbar').toggleClass("active");

            $(this).attr('data-active', 'false');
        } else {
            form.submit();
        }
    });

    // Leftnav + Filters toggle
    $('.js-toggle-left-navigation').off('click').on('click', function () {
        var ToggleSubMenu = $('.left__nav');
        var ToggleMenuButton = $('.js-toggle-left-navigation');

        if (ToggleSubMenu.hasClass('active')) {
            ToggleSubMenu.removeClass('active');
            ToggleMenuButton.removeClass('reverse active');
        } else {
            ToggleSubMenu.addClass('active');
            ToggleMenuButton.addClass('reverse active');
        }
        return false;
    });

    $('.filtertogglebutton').off('click').on('click', function () {
        var ToggleFilter = $('.filter');
        var ToggleFilterButton = $('.filtertogglebutton');

        if (ToggleFilter.hasClass('active')) {
            ToggleFilter.removeClass('active');
            ToggleFilterButton.removeClass('reverse active');
        } else {
            ToggleFilter.addClass('active');
            ToggleFilterButton.addClass('reverse active');
        }
        return false;
    });

    // Smooth scroll
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);

            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 180
                }, 1000);
                return false;
            }
        }
    });

    //scroll at product detail
    $.fn.matchHeight._afterUpdate = function (event, groups) {
        // Sidebar scroll
        var articleScroll = $('.webshop__article .article__scroll');
        var articleScrollHeight = $(articleScroll).outerHeight();
        var footerFromTop = ($('.accessoiries').offset());
        var articleScrollAbsolutePosition = ((footerFromTop - articleScrollHeight) - articleScrollHeight);

        $(articleScroll).outerWidth($(articleScroll).parents('.webshop__article .prod__info').width());

        $(window).scroll(function () {
            if ($(window).scrollTop() > articleScrollAbsolutePosition) {
                $(articleScroll).addClass('absolute');
            } else {
                $(articleScroll).removeClass('absolute');
            }
        });
    };

    // Product Anchors
    $(".js-anchors .anchor__item .anchor__link").click(function () {
        $(this).parent().addClass('selected');
        $(this).parent().siblings().removeClass('selected');
    });


    //FAQ toggle ToggleBox
    $('.faq .open-toggle').hide();
    $('.faq .open').show();
    $('.faq .closed').hide();
    $(".faq .toggle").click(function () {
        $(this).closest('.toggle-box').find(".open-toggle").slideToggle("slow", function () {
            if ($(this).closest('.toggle-box').hasClass('active')) {
                $(this).closest('.toggle-box').removeClass('active');
                $(this).closest('.toggle-box').find('.closed').hide();
                $(this).closest('.toggle-box').find('.open').show();
            } else {
                $(this).closest('.toggle-box').addClass('active');
                $(this).closest('.toggle-box').find('.closed').show();
                $(this).closest('.toggle-box').find('.open').hide();
            }
        });
    });


    // $(".showlogin_button").click(function () {
    //     $('.login__container').addClass('showlogin');
    //     $('.showlogin_button').hide();
    // });


    $(document).ready( function () {
        $('.inputfield input').each(function () {
            if (!$.trim(this.value).length) {
                $(this).addClass('empty');
                $(this).removeClass('has-value');
            } else {
                $(this).addClass('has-value');
                $(this).removeClass('empty');
            }
        });
    });


    $('.inputfield input').blur(function () {
        if (!$.trim(this.value).length) {
            $(this).addClass('empty');
            $(this).removeClass('has-value');
        } else {
            $(this).addClass('has-value');
            $(this).removeClass('empty');
        }
    });


})(jQuery);
