export default function () {
    window.getAllValues = function (section) {
        return $(section).find('form').serializeArray();
    };

    window.getSectionEndpoint = function (section, endpoint) {
        let mainSection = $(section).closest('section[data-module="shop"]');
        if ((typeof mainSection === "undefined") || (mainSection.length === 0)) {
            mainSection = $('section[data-module="shop"]');
        }
        let path = mainSection.data('section').split('.').join('/');
        return $('body').data('baseUrl') + '/api/front-end/' + path + '/' + endpoint;
    };

    let currentRequest = {
        abort: function () {
        }
    };
    let config = {
        'updateMapping': {
            'lines': [
                'totals',
                'giveaways',
                'destinations',
                'paymentMethods',
                'shippingMethods',
            ],
            'destinations': [
                'linesSummary',
                'paymentMethods',
                'shippingMethods',
            ],
            'shippingMethods': [
                'linesSummary',
                'total',
            ],
            'paymentMethods': [
                'linesSummary',
                'total',
            ],
            'coupons': [
                'lines', // Todo: FEF - Checkout - Should be a 'symbolic' link to the lines array above...? It affects lines, thus all that is affected by lines
                'linesSummary',
                'totals', // Todo: FEF - Checkout ------------^
                'giveaways', // Todo: FEF - Checkout ---------^
                'destinations', // Todo: FEF - Checkout ------^
                'paymentMethods', // Todo: FEF - Checkout ----^
                'shippingMethods', // Todo: FEF - Checkout ---^
            ],
            'giveaways': [],
            'userLogin': [
                'address',
                'addressBook',
                'shippingMethods',
                'paymentMethods',
                'lines',
                'linesSummary',
                'total'
            ],
            'address': [
                'userLogin',
                'shippingMethods',
                'paymentMethods',
                'lines',
                'linesSummary',
                'total'
            ],
            'addressBook': [
                'address',
                'shippingMethods',
                'paymentMethods',
                'lines',
                'linesSummary',
                'total'
            ],
            'orderFields': []
        }
    };

    function amountQty(e, plusOrMinus) {
        e.preventDefault();

        let field = $(e.target).parent().find('input.amount');
        let orderPer = $(field).data('order-per') ? parseInt($(field).data('order-per')) : 1;
        let currentVal = parseInt($(field).val());
        let newAmount;

        if (plusOrMinus === 'minus') {
            let minAmount = $(field).data('min-amount') ? parseInt($(field).data('min-amount')) : 1;
            newAmount = currentVal - orderPer;
            newAmount = newAmount < minAmount ? minAmount : newAmount;
        } else {
            newAmount = currentVal + orderPer;
        }

        $(field).attr('value', newAmount);
        $(field).val(newAmount).trigger('change');
    }

    function bindSectionUpdates(selector) {

        $(selector).each(function () {
            $(this).find('.cart__items .item .qtyplus').click(function (e) {
                amountQty(e, 'plus');
            });
            $(this).find('.cart__items .item .qtyminus').click(function (e) {
                amountQty(e, 'minus');
            });

            // MatchHeight CMS Cart / New Cart / Don't delete
            $(this).find('.cart__items .item .inner').matchHeight({
                byRow: true
            });
            $(this).find('.cart__gift .item .matching').matchHeight({
                byRow: true
            });
            $(this).find('.item .matching').matchHeight({
                byRow: true
            });
            $(this).find('.cms__cart__match').matchHeight({
                byRow: true
            });

            $(this).find('.customer__inputs .radio .customerType').change(function () {
                if ($(this).is(':checked')) {
                    $(this).parent().parent().find('.active').removeClass('active');
                    $(this).parent().addClass('active');
                }
                if ($('.customer__inputs .radio input[value="company"]').is(':checked')) {
                    $('.customer__inputs .company__fields').addClass('active');
                } else {
                    $('.customer__inputs .company__fields').removeClass('active');
                }
            });

            $(this).find('.normal__inputs .radio .radiobutton').change(function () {
                if ($(this).is(':checked')) {
                    $(this).parent().parent().find('.active').removeClass('active');
                    $(this).parent().addClass('active');
                }
            });

            $(this).find(".invoice__address .checkbox #invoiceAddressSameAsDelivery").change(function () {
                $(this).parent().toggleClass("active", this.checked);
                if ($('.invoice__address .checkbox #invoiceAddressSameAsDelivery').is(':checked')) {
                    $('.invoice__address .hidden__fields').removeClass('active');
                } else {
                    $('.invoice__address .hidden__fields').addClass('active');
                }
            }).change();

            $(this).find(".register__account .checkbox #password").change(function () {
                $(this).parent().toggleClass("active", this.checked);
                if ($('.register__account .checkbox #password').is(':checked')) {
                    $('.register__account .hidden__fields').addClass('active');
                } else {
                    $('.register__account .hidden__fields').removeClass('active');
                }
            }).change();

            $(this).find('button[data-action], .section-action[data-action]').each(function () {
                $(this).on('click', sectionAction);
            });

            $(this).find('button[data-render-partial]').each(function () {
                $(this).on('click', sectionRenderPartial);
            });

            $(this).find('input[data-action], select[data-action]').each(function () {
                $(this).on('change', sectionAction)
                    .on('keypress', function (e) {
                        if (e.which === 13) {
                            sectionAction(e);
                        }
                    });
            });
        });
    }

    function sectionAction(e) {
        e.preventDefault();

        let input = $(this ? this : e.target);
        let data = $.extend(true, {}, input.data());
        let action = data.action;
        let section = $(input).closest('section[data-section^="shop."][data-section$=".partials"]');
        let val = null;

        if (input.is('input')) {
            val = input.val();
        }
        if (input.is('select')) {
            val = input.children("option:selected").val();
        }

        if (val) {
            if (data.hasOwnProperty('id')) {
                data['id'] = data.id;
            }

            if (data.hasOwnProperty('property')) {
                data[data.property] = val;
                delete data.property;
            } else {
                data[input.attr('name')] = val;
            }
        }

        if (input.is('[data-form]')) {
            window.getAllValues(section).forEach(function (item) {
                data[item.name] = item.value;
            });
        }

        call('action', section, data, data.hasOwnProperty('noPartialUpdate'), action);
    }

    function sectionRenderPartial(e) {
        e.preventDefault();

        let input = $(this ? this : e.target);
        let data = $.extend(true, {}, input.data());
        if (data.hasOwnProperty('renderPartial')) {
            let section = $('section[data-section^="shop."][data-section$=".partials"][data-partial="' + data.renderPartial + '"]');
            call('renderPartial', section, data, false);
        }

    }

    function call(endpoint, section, data, skipInitiatingPartial = false, action = null) {
        currentRequest.abort();

        let partial = section.data('partial');

        if (config.updateMapping.hasOwnProperty(partial)) {
            // Add loading class to all partials to be replaced
            let currentSections = [];
            if (!skipInitiatingPartial) {
                currentSections.push(partial);
            }
            if (!data.hasOwnProperty('partialOnly')) {
                for (let i = 0; i < config.updateMapping[partial].length; i++) {
                    let currentSection = $('section[data-section^="shop."][data-section$=".partials"][data-partial="' + config.updateMapping[partial][i] + '"]');
                    if (currentSection.length) {
                        currentSection.addClass('loading');
                        currentSections.push(config.updateMapping[partial][i]);
                    }
                }
            }

            // Todo: FEF - Create CMS specific class available for general setting/usage in FEF (baseUrl)
            currentRequest = $.ajax({
                type: 'POST',
                url: window.getSectionEndpoint(section, endpoint),
                beforeSend: function () {
                    if (currentRequest != null) {
                        currentRequest.abort();
                    }
                },
                data: JSON.stringify({
                    'language': $('body').data('language'),
                    'partial': partial,
                    'partials': currentSections,
                    'data': data,
                    'action': action
                }),
                dataType: 'json',
                success: function (data) {
                    if (data.hasOwnProperty('refresh')) {
                        location.reload();
                    } else {
                        for (let partialSection in data.partials) {
                            let sectionSelector = 'section[data-section^="shop."][data-section$=".partials"][data-partial="' + partialSection + '"]';
                            $(sectionSelector).replaceWith(data.partials[partialSection]);

                            if ((partialSection === 'addressBook') && !$.fancybox.getInstance()) {
                                $.fancybox.open({
                                    src: '.checkout-address__popup',
                                    type: 'inline',
                                    opts: {
                                        afterShow: function (instance, current) {
                                            bindSectionUpdates(sectionSelector);
                                        }
                                    }
                                });
                            } else {
                                bindSectionUpdates(sectionSelector);
                            }
                        }

                        if (data.incomplete) {
                            for (let incompleteSection in data.incomplete) {
                                let sectionSelector = 'section[data-section^="shop."][data-section$=".partials"][data-partial="' + incompleteSection + '"]';
                                $(sectionSelector).data('incomplete', data.incomplete[incompleteSection]);
                            }
                        }
                    }
                },
                error: function (e) {
                    if (e.hasOwnProperty('responseJSON') && e.responseJSON) {
                        let errors = e.responseJSON.errors;

                        let partialNode = $('section[data-section^="shop."][data-section$=".partials"][data-partial="' + partial + '"]');
                        partialNode.find('input').removeClass('error');
                        partialNode.find('[data-error-input]').empty();

                        for (let field in errors) {
                            if (errors.hasOwnProperty(field)) {
                                partialNode.find('[name="' + field + '"]').addClass('error');
                                partialNode.find('[data-error-on-name="' + field + '"]').addClass('error');
                                partialNode.find('[data-error-input="' + field + '"]').html(errors[field]);
                            }
                        }
                    }
                }
            });
        }
    }

    bindSectionUpdates('section[data-section^="shop."][data-section$=".partials"]');

    $('section[data-section^="shop."][data-section$=".navigation"]').each(
        function () {
            $(this).find('button[data-step], .section-action[data-step]').each(function () {
                $(this).on('click', navigationAction);
            });
        }
    );

    function navigationAction(e) {
        e.preventDefault();

        let input = $(this ? this : e.target);

        if (!$(input).hasClass('fired')) {
            $(input).addClass('fired');

            let partials = {};
            let sections = $('section[data-section^="shop."][data-section$=".partials"]:not([data-action-only])');
            sections.each(function () {
                let partial = $(this).data('partial');
                // // Todo: Check 'dirty' sections? (Not yet fully processed/loading...?)
                partials[partial] = {
                    'set': $(this).data('set'),
                    'data': window.getAllValues(this)
                };
            });

            $.ajax({
                type: 'POST',
                url: window.getSectionEndpoint(sections, 'navigation'),
                data: JSON.stringify({
                    'language': $('body').data('language'),
                    'step': input.data('step'),
                    'partials': partials,
                }),
                dataType: 'json',
                success: function (data) {
                    if (data.hasOwnProperty('redirect')) {
                        location = data.redirect;
                    }
                },
                error: function (e) {
                    if (e.hasOwnProperty('responseJSON')) {
                        let errors = e.responseJSON.errors;
                        for (let partial in errors) {
                            if (errors.hasOwnProperty(partial)) {
                                let partialNode = $('section[data-section^="shop."][data-section$=".partials"][data-partial="' + partial + '"]');
                                partialNode.find('input').removeClass('error');
                                partialNode.find('[data-error-input]').empty();

                                let partialMessages = errors[partial];

                                for (let field in partialMessages) {
                                    if (partialMessages.hasOwnProperty(field)) {
                                        partialNode.find('[name="' + field + '"]').addClass('error');
                                        partialNode.find('[data-error-on-name="' + field + '"]').addClass('error');
                                        partialNode.find('[data-error-input="' + field + '"]').html(partialMessages[field]);
                                    }
                                }
                            }
                        }

                        $("html, body").animate({scrollTop: Math.max(0, $('section[data-section^="shop."][data-section$=".partials"]').find('.error').first().offset().top - 50)}, "slow");
                    }

                    $(input).removeClass('fired');
                }
            });
        }
    }
}
