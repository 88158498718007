export default function (options) {
    let defaults = {
            timeoutInfoMessages: 0,
        },
        settings = $.extend(defaults, options);

    // Info Message fadeout
    if (settings.timeoutInfoMessages > 0) {
        setTimeout(function () {
            $('.info.message').fadeOut('fast');
        }, settings.timeoutInfoMessages);
    }

    if ($(".product").length) {
        $(document).ready(function () {
            checkOrderValue();

            // Article '+' and '-' buttons
            $(".product .qtyminus").click(function (e) {
                e.preventDefault();
                let minus = 'minus';
                checkOrderValue(minus);
            });
            $('.product .qtyplus').click(function (e) {
                e.preventDefault();
                let plus = 'plus';
                checkOrderValue(plus);
            });
            $('.product input.amount').on("change", function (e) {
                e.preventDefault();
                let inputChange = 'input';
                checkOrderValue(inputChange);
            });
        });
    }

    let minAmount = 1;
    let orderPer = 1;

    function checkOrderValue(plusorminus) {
        //if minamount and orderper exist -> fetch them
        minAmount = (typeof $('.min-amount').data('min-amount') !== 'undefined') ? $('.min-amount').data('min-amount') : 1;
        orderPer = (typeof $('.order-per').data('order-per') !== 'undefined') ? $('.order-per').data('order-per') : 1;

        let currentAmount = parseInt($('input.amount').val(), 10);
        let newAmount;

        // set min, step and startvalue (minamount)
        $('input.amount').attr({
            min: minAmount,
            step: orderPer,
            value: minAmount
        });

        // if plus is pressed
        if (plusorminus == 'plus') {
            // add step amount to current amount
            newAmount = currentAmount + orderPer;
            $('input.amount').attr('value', newAmount);
        } else if (plusorminus == 'minus') { //if minus is pressed
            // remove step amount from current amount
            newAmount = currentAmount - orderPer;
            if (newAmount < minAmount) {
                $('input.amount').attr('value', minAmount);
            } else {
                $('input.amount').attr('value', newAmount);
            }
        } else if (plusorminus == 'input') { //if the input is changed
            // if the given amount is lower than minimum, set minimum as current
            if (currentAmount < minAmount) {
                $('input.amount').attr('value', minAmount);
            } else { // else use given amount
                $('input.amount').attr('value', currentAmount);
            }
        } else { // fallback to minimum amount
            $('input.amount').attr('value', minAmount);
        }

        // set the input text. Fetched from input value attribute (changed above). Set currentAmount for the next calculations.
        $('input.amount').val($('input.amount').attr('value'));
        currentAmount = parseInt($('input.amount').val());

        // loop through staffels and see if the given amount of products is within any of the staffels (if so, set the price to staffelprice)
        $("#staffeltable tr").each(function() {
            var currentStaffelAmount = $(this).find('td:nth-child(1)').html(); // staffel amount
            var currentStaffelPrice = $(this).find('td:nth-child(2)').html(); // staffel price

            // if given / selected amount of products is more than the current staffel -> set price to this staffelprice.
            if (currentAmount >= currentStaffelAmount) {
                $('.prices .price').text(currentStaffelPrice);
            }
        });

    }

    // Articles image gallery
    // Preview image
    $('.js-gallery .inner').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        arrows: false,
        draggable: false,
        dots: false,
        fade: true,
        asNavFor: '.js-gallery-nav',
        adaptiveHeight: true
    });

    // Thumbnail images
    $('.js-gallery-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 0,
        swipeToSlide: true,
        focusOnSelect: true,
        centerMode: false,
        asNavFor: '.js-gallery .inner',
        prevArrow: $('.prevArrow'),
        nextArrow: $('.nextArrow'),
        vertical: false,
        responsive: [
            {
                breakpoint: 4000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 990,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }]
    });

    // Article image enlargement
    $('[data-fancybox="gallery"]').fancybox({
        loop: true,
        afterShow: function (instance, slide) {
            $('.js-gallery').slick('slickGoTo', slide.index);
            $('.js-gallery-nav').slick('slickGoTo', slide.index);
        },
        buttons: [
            'slideShow',
            'fullScreen',
            'thumbs',
            'close'
        ]
    });
}